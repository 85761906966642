<template>
  <div class="js-common-wrap js-setLogo">
    <div class="js-his-records-head">
      <label>企业Logo设置</label>
    </div>
    <div class="js-common-content">
      <div v-if="!isReupdate">
        <img class="image-url" :src="imageUrl" alt="" />
        <div class="again" type="primary" @click="uploadAgain">
          <div class="text">重新上传</div>
        </div>
      </div>
      <template v-else>
        <el-upload
          action="#"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleHttpRequest"
        >
          <div class="image-url reupdate" @click="handleHttpRequest">
            <img src="@/assets/images/13.png" alt="" />
          </div>
          <el-button class="again" size="small" type="primary">
            <div class="text">点击上传</div>
          </el-button>
          <div slot="tip" class="el-upload__tip">
            您可以为作业报告添加属于自己企业的Logo。支持*.jpg、*.png、*.gif格式图片，分辨率158x64
          </div>
        </el-upload>
      </template>
      <p class="title">样式参考</p>
      <img class="logo" src="@/assets/images/reference-style.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      action: "",
      imageUrl: "",
      imgType: "",
      isReupdate: false,
    };
  },
  mounted() {
    this.getLogo();
  },
  methods: {
    getLogo() {
      this.$axios({
        method: "get",
        url: "api/csp/sop/v1/sopReportLogo/",
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.imageUrl = data.data.fullLogoUrl;
          this.isReupdate = false;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    uploadAgain() {
      // this.imageUrl = ''
      this.isReupdate = true;
    },
    // 获取上传文件地址
    getUploadDir() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: "api/csp/cos/v1/url/upload",
          params: {
            dirType: "upload",
            suffix: this.imgType,
          },
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              this.action = data.data;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    sizeCheck(file) {
      const isSize = new Promise(function (resolve, reject) {
        let width = 158;
        let height = 64;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message({
            type: "error",
            message: "上传的icon必须是158*64!",
            duration: 1000,
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    beforeUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/gif";
      if (!isJPG) {
        this.$message({
          type: "error",
          message: "图片格式有误!",
          duration: 1000,
        });
        return;
      }
    },
    async handleHttpRequest(file) {
      // let size = this.sizeCheck(file)
      this.beforeUpload(file.raw);
      this.imgType = file.raw.type.split("/")[1];
      console.log(this.imgType);
      await this.getUploadDir();
      var xhr = new XMLHttpRequest();
      console.log(this.action);
      xhr.open("PUT", this.action.url, true);
      xhr.onload = (e) => {
        // console.log('上传成功', xhr.status, xhr.statusText)
        this.submitImage(file.name, file.size, this.action.file);
      };
      xhr.onerror = (e) => {
        // console.log('上传出错', xhr.status, xhr.statusText)
      };
      xhr.send(file.raw);
    },
    // 提交图片
    submitImage(name, size, url) {
      this.$axios({
        method: "post",
        url: "api/csp/sop/v1/sopReportLogo/",
        data: {
          data: {
            name,
            size,
            logoUrl: url,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          // 上传成功，更新logo
          this.getLogo();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.js-setLogo {
  .js-common-content {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
  }
  .title {
    margin-top: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid $jsTheme;
  }
  .logo {
    margin-top: 10px;
    width: 100%;
  }
  .url {
    margin-left: 10px;
    color: #999;
  }
  .image-url {
    width: 158px;
    height: 64px;
    vertical-align: bottom;

    &.reupdate {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #6e42dc;
    }
  }
  .again {
    width: 142px;
    height: 50px;
    background: #6e42dc;
    border-radius: 27px;
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      width: 80px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
    }
  }
}
</style>
