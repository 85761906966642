var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-setLogo" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "js-common-content" },
      [
        !_vm.isReupdate
          ? _c("div", [
              _c("img", {
                staticClass: "image-url",
                attrs: { src: _vm.imageUrl, alt: "" },
              }),
              _c(
                "div",
                {
                  staticClass: "again",
                  attrs: { type: "primary" },
                  on: { click: _vm.uploadAgain },
                },
                [_c("div", { staticClass: "text" }, [_vm._v("重新上传")])]
              ),
            ])
          : [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "#",
                    "show-file-list": false,
                    "auto-upload": false,
                    "on-change": _vm.handleHttpRequest,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-url reupdate",
                      on: { click: _vm.handleHttpRequest },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/13.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "again",
                      attrs: { size: "small", type: "primary" },
                    },
                    [_c("div", { staticClass: "text" }, [_vm._v("点击上传")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        " 您可以为作业报告添加属于自己企业的Logo。支持*.jpg、*.png、*.gif格式图片，分辨率158x64 "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
        _c("p", { staticClass: "title" }, [_vm._v("样式参考")]),
        _c("img", {
          staticClass: "logo",
          attrs: {
            src: require("@/assets/images/reference-style.png"),
            alt: "",
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "js-his-records-head" }, [
      _c("label", [_vm._v("企业Logo设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }